main{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items:start;
  flex-grow: 1;
}

.team-container{
  width: 40%;
  padding: 2rem;
  /* border: 1px black solid; */
}

.self{
  box-shadow: inset 0px 0px 15px #00ddff;
}

.enemy{
  box-shadow: inset 0px 0px 15px #ff0000;
}

.team-container > h1, .team-container > h2{
  border-bottom: 1px black solid;
  margin-bottom: 1rem;
}

.selector-collection{
  display: flex;
  flex-direction: row;
}

div#root > h1{
  text-align: center;
}

.team-container > h2{
  width: max-content;
  max-width: fit-content;
}